import { PracticeScheduleReducer } from 'reducers/practiceSchedule';
import { getEvent } from 'selectors/eventsSelectors';
import { getSetNumber } from 'selectors/practiceScheduleSelectors';
import { EpisodeEvent, QzmCurrentWeekGame } from 'types/api/episodeApi';
import { getClientTime } from 'utils/clientTime';
import { setEvents } from './eventActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';
import { push } from './history';

function getIndexByType(events: EpisodeEvent[], event: EpisodeEvent) {
  const targetEvents = events.filter(({ type }) => type === event.type);

  return targetEvents.indexOf(event);
}

export const fetchPracticeGame =
  (setNumber: number): ThunkAction<Promise<void>> =>
  async (dispatch, _getState, { api }) => {
    try {
      const {
        set: { events },
      } = await api.put<QzmCurrentWeekGame>(`qzm/practiceset/${setNumber}`);
      const practiceEvents = events.map((event) => ({
        ...event,
        episodeCode: 'practice',
        eventId: event.eventId || `${setNumber}-${event.type}-${getIndexByType(events, event)}`,
      }));

      // TODO Clear previous game data?
      dispatch(setEvents(practiceEvents));
    } catch (error) {
      throw error;
    }
  };

export const startPracticeGame = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const setNumber = getSetNumber(state);

  await dispatch(fetchPracticeGame(setNumber));
  dispatch(resetPractice());
  dispatch(setPracticeStartTime(getClientTime()));
  dispatch(push('/practice'));
};

export const startNextPracticeGame =
  (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const setNumber = getSetNumber(state);
    const newSet = setNumber + 1;

    await dispatch(fetchPracticeGame(newSet));
    dispatch(resetPractice());
    dispatch(
      setSchedule({
        setNumber: newSet,
      }),
    );
    dispatch(setPracticeStartTime(getClientTime()));
    dispatch(push('/practice'));
  };

export const resumePracticeGame = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const setNumber = getSetNumber(state);

  await dispatch(fetchPracticeGame(setNumber));
  dispatch(push('/practice'));
  dispatch(unpause());
};

export const startMockPracticeGame = (): ThunkAction<Promise<void>> => async (dispatch) => {
  try {
    const response = await fetch('/mocks/talpa_episodes_currentweekgame.json');
    const {
      set: { events },
    } = (await response.json()) as QzmCurrentWeekGame;
    const practiceEvents = events.map((event) => ({ ...event, episodeCode: 'practice' }));

    dispatch(setEvents(practiceEvents));
    dispatch(setPracticeStartTime(getClientTime()));
  } catch (error) {
    throw error;
  }
};

export const unpause = (): ThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const { nextEventId } = state.practiceSchedule;

  if (!nextEventId) {
    return;
  }

  const event = getEvent(state, nextEventId);

  if (!event) {
    return;
  }

  const firstPhase = event.phases[0];

  if (!firstPhase) {
    return;
  }

  // Start practice schedule at start of next event
  dispatch(unpauseAt(getClientTime() - firstPhase.offset));
};

export const setPracticeStartTime = (startAt: number) =>
  createActionWithPayload('@practiceSchedule/START', startAt);

export const setSchedule = (payload: Partial<PracticeScheduleReducer>) =>
  createActionWithPayload('@practiceSchedule/SET_SCHEDULE', payload);

export const pause = () => createAction('@practiceSchedule/PAUSE');

export const unpauseAt = (offset: number) =>
  createActionWithPayload('@practiceSchedule/UNPAUSE_AT', offset);

export const resetPractice = () => createAction('@practiceSchedule/RESET');

export type PracticeActions = ReturnType<
  | typeof setPracticeStartTime
  | typeof setSchedule
  | typeof pause
  | typeof unpauseAt
  | typeof resetPractice
>;
