import { push } from 'actions/history';
import { logout } from 'actions/usersApiActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import EpisodeCountdown from 'components/EpisodeCountdown';
import Link from 'components/Link';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import PanelFloatingCard from 'components/Panel/PanelFloatingCard';
import PlayerCard from 'components/PlayerCard';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import GameScreen from 'screens/Game';
import { isEpisodeJoined } from 'selectors/episodeScheduleSelectors';
import { getEpisode, getEpisodeStartDateTime } from 'selectors/episodeSelectors';
import { getIsLoggedIn, getStoredProfile } from 'selectors/userSelectors';

const DashboardScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const episodeStartDate = useSelector(getEpisodeStartDateTime);
  const { firstName, gender, lastName, avatarUrl, email, ageGroup, location } =
    useSelector(getStoredProfile);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const episodeJoined = useSelector(isEpisodeJoined);
  const episode = useSelector(getEpisode);

  if (episodeJoined) {
    return <GameScreen />;
  }

  return (
    <>
      <TopBar logo left={<MenuToggle />} />

      {episode ? <EpisodeCountdown date={episodeStartDate} /> : <Spacer spacing="large" />}

      {!isLoggedIn ? (
        <Panel>
          <Box paddingX="gutter" display="flex" flexDirection="column" alignItems="center">
            <Stack spacing="large" align="center">
              <Stack spacing="small" align="center">
                <Avatar size="medium" />
                <Text textAlign="center" fontSize="small">
                  {t(
                    'Would you like to qualify as a studio candidate and play for the app jackpot on Friday?',
                  )}
                </Text>
                <Link
                  color="black"
                  fontSize="small"
                  underline
                  onClick={() => {
                    dispatch(logout(true));
                  }}
                >
                  {t('Create a profile')}
                </Link>
              </Stack>
            </Stack>
          </Box>
        </Panel>
      ) : (
        <PanelFloatingCard
          card={
            <PlayerCard
              avatarUrl={avatarUrl}
              ageGroup={ageGroup}
              email={email}
              gender={gender}
              firstName={firstName}
              lastName={lastName}
              location={location}
            />
          }
          onCardClick={() => dispatch(push('/profileoverview'))}
        />
      )}
    </>
  );
};

export default DashboardScreen;
