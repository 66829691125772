import {
  FacebookShareCommand,
  FacebookSignInCommand,
  GoogleSignInCommand,
  GoogleSignOutCommand,
  OverlayOpenCommand,
  StorageGetItemCommand,
  StorageRemoveItemCommand,
  StorageSetItemCommand,
} from 'types/bridge';
import { ThunkAction } from './helpers';
import { setLoading } from './photoActions';
import { isIOSWrapper } from 'utils/platform';

export const getBatteryLevel =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'GetBatteryLevel' });

export const getNetworkStatus =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'GetNetworkStatus' });

export const hideLoading =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'HideLoading' });

export const openBrowser =
  (url: string): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'BrowserOpen', payload: { url } });

export const openOverlay =
  (payload: OverlayOpenCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'OverlayOpen', payload });

export const ready =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Event', subject: 'AppReady' });

export const requestMicrophoneAccess =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'RequestMicrophoneAccess' });

export const startAudioSync =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'StartAudioSync' });

export const stopAudioSync =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'StopAudioSync' });

// Social - Facebook

export const signInFacebook =
  (payload: FacebookSignInCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) => {
    return bridge.sendMessage({ type: 'Command', subject: 'FacebookLogin', payload });
  };

export const signOutFacebook =
  (): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) => {
    return bridge.sendMessage({ type: 'Command', subject: 'FacebookLogout' });
  };

export const graphFacebook =
  (fields: string): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({
      type: 'Command',
      subject: 'FacebookGraphRequest',
      payload: {
        path: 'me',
        httpMethod: 'get',
        parameters: {
          fields,
        },
      },
    });

export const shareFacebook =
  (payload: FacebookShareCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'FacebookShare', payload });

export const takePhoto =
  (): ThunkAction<void> =>
  (dispatch, _getState, { bridge }) => {
    dispatch(setLoading(true));

    bridge.sendMessage({
      type: 'Command',
      subject: 'TakePhoto',
      payload: {
        width: 200,
        height: 200,
      },
    });
  };

export const selectPhoto =
  (): ThunkAction<void> =>
  (dispatch, _getState, { bridge }) => {
    dispatch(setLoading(true));

    bridge.sendMessage({
      type: 'Command',
      subject: 'SelectPhoto',
      payload: {
        width: 200,
        height: 200,
      },
    });
  };

// Social - Google
export const signInGoogle =
  (payload: GoogleSignInCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'GoogleSignIn', payload });

export const signOutGoogle =
  (payload: GoogleSignOutCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) =>
    bridge.sendMessage({ type: 'Command', subject: 'GoogleSignOut', payload });

// iOS Native Storage
export const storageSetItem =
  (payload: StorageSetItemCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) => {
    if (!isIOSWrapper) {
      return;
    }

    bridge.sendMessage({
      type: 'Command',
      subject: 'StorageSetItem',
      payload,
    });
  };

export const storageGetItem =
  (payload: StorageGetItemCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) => {
    if (!isIOSWrapper) {
      return;
    }

    bridge.sendMessage({
      type: 'Command',
      subject: 'StorageGetItem',
      payload,
    });
  };

export const storageRemoveItem =
  (payload: StorageRemoveItemCommand['payload']): ThunkAction<void> =>
  (_dispatch, _getState, { bridge }) => {
    if (!isIOSWrapper) {
      return;
    }

    bridge.sendMessage({
      type: 'Command',
      subject: 'StorageRemoveItem',
      payload,
    });
  };
